<template>
  <div class="container layout-container account-tabs-border zoom">
    <b-row>
      <b-col cols="12">
        <h3 class="page-title my-5">{{$t("profile.titles.title")}}</h3>
      </b-col>
      <b-col cols="12">
        <div>
          <b-tabs v-model="tabIndex" content-class="mt-4">
            <b-tab :title="$t('profile.titles.tab1')" active>
              <b-row>
                <b-col cols="12">
                  <b-card  no-body>
                    <b-tabs v-model="subTabIndex.profile" pills card vertical>
                      <b-tab :title="$t('profile.titles.subtab1')" active
                        ><b-card-text  >
                          <profile-tabs></profile-tabs> </b-card-text
                      ></b-tab>

                     
                      <b-tab :title="$t('profile.titles.subtab2')"
                        ><b-card-text> <my-keywords></my-keywords> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab3')"
                        ><b-card-text> <login-tabs></login-tabs> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab4')"
                        ><b-card-text> <impressions></impressions> </b-card-text
                      ></b-tab>

                    <!-- <div style="height:3px; background:#a7a7a7"></div> -->

                      <b-tab  :title="$t('profile.titles.subtab-c')"
                      >
                        <b-card-text> 
                            <company-tabs></company-tabs>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab  :title="$t('profile.titles.tab2')"
              ><p>
                <b-row>
                  <b-col cols="12">
                    <b-card no-body>
                      <b-tabs pills card vertical>
                        <b-tab  :title="$t('profile.titles.subtab5')" active
                          ><b-card-text> <general></general> </b-card-text
                        ></b-tab>
                      </b-tabs>
                    </b-card>
                  </b-col>
                </b-row></p
            ></b-tab>
            <b-tab :title="$t('profile.titles.tab3')">
              <b-row>
                <b-col cols="12">
                  <b-card no-body>
                    <b-tabs v-model="subTabIndex.plan" pills card vertical>
                      <b-tab :title="$t('profile.titles.subtab6')" active
                        ><b-card-text>
                          <plan-overview></plan-overview> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab7')"
                        ><b-card-text>
                          <plan-payment></plan-payment> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab8')"
                        ><b-card-text> <billings></billings> </b-card-text
                      ></b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('profile.titles.tab4')">
              <b-row>
                <b-col cols="12">
                  <b-card no-body>
                    <b-tabs pills v-model="subTabIndex.notifications" card vertical>
                      <b-tab :title="$t('profile.titles.subtab9')" active
                        ><b-card-text> <notify-tab></notify-tab> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab10')"
                        ><b-card-text>
                          <notify-desktop-tab></notify-desktop-tab> </b-card-text
                      ></b-tab>
                      <b-tab :title="$t('profile.titles.subtab11')">
                        <b-card-text>
                          <alert-sounds></alert-sounds> </b-card-text
                      ></b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('profile.titles.tab5')">
              <b-row>
                <b-col cols="12">
                  <b-card no-body>
                    <b-tabs pills card vertical>
                      <b-tab :title="$t('profile.titles.subtab12')" active
                        ><b-card-text>
                          <privacy-settings></privacy-settings></b-card-text
                      ></b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import _ from 'lodash';

export default {
  components: {
    Treeselect,
    profileTabs: () => import("@/components/app/profile/profile"),
    loginTabs: () => import("@/components/app/profile/loginTab"),
    companyTabs: () => import("@/components/app/profile/company"),
    planTab: () => import("@/components/app/profile/plan"),
    planOverview: () => import("@/components/app/profile/planOverview"),
    planPayment: () => import("@/components/app/profile/planPaymentDetails"),
    billings: () => import("@/components/app/profile/planBillings"),
    notifyTab: () => import("@/components/app/profile/notifyTab"),
    notifyDesktopTab: () => import("@/components/app/profile/notifyDesktopTab"),
    langTab: () => import("@/components/app/profile/langUpdate"),
    myKeywords: () => import("@/components/app/profile/myKeywords"),
    impressions: () => import("@/components/app/profile/impressions"),
    general: () => import("@/components/app/profile/general"),
    privacySettings: () => import("@/components/app/profile/privacySettings"),
    alertSounds: () => import("@/components/app/profile/alertSounds"),
  },
  data() {
    return {
      tabIndex: 0,
      subTabIndex: {
          profile: 0,
          plan: 0,
          notifications: 0
      },
      tabs: [
          {
              index: 0,
              tab: 'profile',
              router: '/app/profile/settings/profile',
              child: [
                  {
                      index: 0,
                      tab: 'about-me',
                      router: '/app/profile/settings/profile/about-me'
                  },
                  {
                      index: 1,
                      tab: 'keywords',
                      router: '/app/profile/settings/profile/keywords'
                  },
                  {
                      index: 2,
                      tab: 'security',
                      router: '/app/profile/settings/profile/security'
                  },
                  {
                      index: 3,
                      tab: 'share',
                      router: '/app/profile/settings/profile/share'
                  },
                  {
                      index: 4,
                      tab: 'company',
                      router: '/app/profile/settings/profile/company'
                  }
              ]
          },
          {
              index: 1,
              tab: 'verification',
              router: '/app/profile/settings/verification'
          },
          {
              index: 2,
              tab: 'plan',
              router: '/app/profile/settings/plan',
              child: [
                  {
                      index: 0,
                      tab: 'plans',
                      router: '/app/profile/settings/plan/plans'
                  },
                  {
                      index: 1,
                      tab: 'payment-details',
                      router: '/app/profile/settings/plan/payment-details'
                  },
                  {
                      index: 2,
                      tab: 'billing',
                      router: '/app/profile/settings/plan/billing'
                  }
              ]
          },
          {
              index: 3,
              tab: 'notifications',
              router: '/app/profile/settings/notifications',
              child: [
                  {
                      index: 0,
                      tab: 'email',
                      router: '/app/profile/settings/notifications/email'
                  },
                  {
                      index: 1,
                      tab: 'platform',
                      router: '/app/profile/settings/notifications/platform'
                  },
                  {
                      index: 2,
                      tab: 'sound',
                      router: '/app/profile/settings/notifications/sound'
                  }
              ]
          },
          {
              index: 4,
              tab: 'privacy',
              router: '/app/profile/settings/privacy'
          }
      ]
    };
  },
  watch: {
      $route: {
          handler() {
              this.bindRoute();
          },
          deep: true
      },

      tabIndex(newTab, oldTab) {
          this.changeTab(newTab, oldTab);
          return true;
      },
      'subTabIndex.profile'(newTab, oldTab) {
          this.changeSubTab(newTab, oldTab);
          return true;
      },
      'subTabIndex.plan'(newTab, oldTab) {
          this.changeSubTab(newTab, oldTab);
          return true;
      },
      'subTabIndex.notifications'(newTab, oldTab) {
          this.changeSubTab(newTab, oldTab);
          return true;
      }
  },
  methods: {
    async changeTab(currentTab, previousTab) {
      await this.$nextTick();
      let index = _.findIndex(this.tabs, x => x.index === currentTab);
      if (index != -1) {
          this.$router.push(this.tabs[index].router).catch(() => {});  
          return;
      }
    },

    async changeSubTab(currentTab, previousTabs) {
      await this.$nextTick();
      let index = _.findIndex(this.tabs, x => x.index === this.tabIndex);
      if (index !== -1) {
          let sindex = _.findIndex(this.tabs[index].child, x => x.index === currentTab);
          if (sindex !== -1) {
              this.$router.push(this.tabs[index].child[sindex].router).catch(() => {});
          }

          return;
      }
    },

    async bindRoute() {
        await this.$nextTick();
        let tab = this.$route.params.tab;
        let subTab = this.$route.params.subtab;
        let index = _.findIndex(this.tabs, x => x.tab === tab);
        if (index !== -1) {
            let sindex = _.findIndex(this.tabs[index].child, x => x.tab === subTab);
            this.tabIndex = this.tabs[index].index;
            if (sindex !== -1) {
                this.subTabIndex[tab] = this.tabs[index].child[sindex].index;
            }

            return;
        }

        return this.$router.push("/app/profile/settings/profile").catch(() => {});
    }
  },
  async created() {
      this.bindRoute();
  },
};
</script>

<style>
</style>